<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4">
        <h4 class="title-in-h">{{$t('property.index_one.header')}}</h4>
        <breadcrumb>
          <breadcrumb-item v-for="breadcrumb in breadcrumbs" v-if="breadcrumb.type!=='active'">
            <router-link :to="{path:breadcrumb.path}" class="color-blue-var">{{breadcrumb.name}}</router-link>
          </breadcrumb-item>
          <breadcrumb-item active v-else>{{breadcrumb.name}}</breadcrumb-item>
        </breadcrumb>
      </div>
      <div class="col-md-8 d-none">
        <l-alert
          type="danger"
          dismissible
          icon="fa fa-exclamation-circle"
        >Tienes hasta el día 6 de "mes en curso" para conciliar tus reservaciones</l-alert>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <card>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="mb-3" style="width: 200px">
                <l-button
                  class="btn-default"
                  @click="createItem()"
                  v-if="currentUser.is_admin"
                >{{$t('property.index.new')}}</l-button>
              </div>
              <el-input
                type="search"
                class="mb-3"
                style="width: 200px"
                :placeholder="$t('property.index.search') "
                v-model="searchQuery"
                aria-controls="datatables"
              />
            </div>
            <div class="col-sm-12">
              <el-table class="table-bigboy" style="width: 100%" :data="queriedData" stripe>
                <el-table-column min-width="220" :label="$t('property.index.property') ">
                  <template slot-scope="{row}" >
                    <div class="lbl-container" >
                      <label class="title">{{row.name}}</label>

                      <el-rate
                              v-model="row.category"
                              disabled
                              text-color="#ff9900">
                      </el-rate>


                      <!--<label v-html="createStars()"></label>-->
                      <label>{{row.place}}</label>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="220" :label="$t('form.reservations') ">
                  <template slot-scope="{row}">
                    <div class="lbl-container text-right">
                      <label>Pendientes por confirmar: {{row.reservas_pendientes}}</label>
                      <label>Llegadas: {{row.reservas_llegadas}}</label>
                      <label>Cancelaciones: {{row.reservas_canceladas}}</label>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="$t('form.status') ">
                  <template slot-scope="{row}">
                    <div class="lbl-container text-center">
                      <label
                        class="font-weight-bold"
                        :class="row.status?'':'text-danger'"
                      >{{trans_status(row.status)}}</label>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="120" label="Acciones" v-if="currentUser.is_admin">
                  <template slot-scope="{row}">
                    <div class="text-center">
                      <a
                        v-tooltip.top-center="$t('form.edit')"
                        class="btn-warning btn-simple btn-link"
                        @click="handleEdit(row._id)"
                      >
                        <i class="el-icon-edit"></i>
                      </a>

                      <!-- <a v-tooltip.top-center="$t('form.delete')" class="btn-danger btn-simple btn-link"
                                           @click="handleDelete(row)" >
                                            <i class="fa fa-times"></i>
                      </a>-->
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p class="card-category" v-if="total>0">
                {{$t('form.showing_a')}} {{from + 1}} {{$t('form.showing_b')}} {{to}}
                {{$t('form.showing_c')}} {{total}} {{$t('form.showing_d')}}
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            ></l-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>

// Componentes
import { Table, TableColumn, Select, Option, Loading, Rate } from 'element-ui'
import { Pagination as LPagination, Breadcrumb, BreadcrumbItem } from 'src/components/index'
import swal from 'sweetalert2'
import LAlert from 'src/components/Alert';

// Librerias
import EventBus from "src/js/helpers/EventBus";
import Fuse from 'fuse.js'

// API Services
import PropertyService from '../../../js/services/PropertyService';
import ReservationService from '../../../js/services/ReservationService';

// Eliminar
import Helpers from "src/js/helpers/Helpers";
import Settings from "src/js/helpers/Settings";
let settings = new Settings();
let helpers = new Helpers();

import moment from 'moment';
// Intancias API
const propertyService = new PropertyService();
const reservationService = new ReservationService();

// VUEX
import { mapGetters } from "vuex";

export default {

  components: {
    LPagination,
    LAlert,
    Breadcrumb,
    BreadcrumbItem,
      [Rate.name]: Rate,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    ...mapGetters(["currentUser", "selected_property", "properties"]),
    pagedData() {
      return this.tableData.slice(this.from, this.to)
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      let result = this.tableData
      if (this.searchQuery !== '') {
        result = this.fuseSearch.search(this.searchQuery)
        this.pagination.total = result.length
      }
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    }
  },
  data() {
    return {
        log_page:{
            action: null,
            section: null,
            property_id: null,
            data: [],
            date: new Date(),
            object_id: null,
            user_id: null,
        },
        info_page:{
            original_info:{},
            section_name: 'property',
        },
      fullscreenLoading: false,
      breadcrumbs: [
        {
          name: this.$t('property.index.home'),
          path: '/admin/properties',
          type: '',
        },
        {
          name: this.$t('property.index.title'),
          path: '',
          type: 'active',
        }
      ],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      propertyAux: [],
      searchQuery: '',
      propsToSearch: ['name'],
      tableData: [],
      fuseSearch: null,
      section: 'PROPERTY'
    }
  },
  created() {
    this.chargerItems();
  },
  methods: {
    async chargerItems() {
        this.initLoading();
      this.$store.dispatch('CHANGE_SECTION', this.section);



        let properties = [...this.properties];




        let reservationsIds = properties.map(item => item._id)


        let reservations_arrive = await reservationService.getReservationsDashboard({properties: reservationsIds});
        let response = reservations_arrive.data.data.reservations;
        let  mergedList =  properties.map(t1 => ({...t1, ...response.find(t2 => t2._id === t1._id)}));
        this.tableData = mergedList;


        this.closeLoading();

      this.fuseSearch = new Fuse(this.tableData, { keys: ['name'] })

    },

      async updateData(properties, idx){


        let reservationsIds = properties.map(item => item._id)


          let reservations_arrive = await reservationService.getReservationsDashboard({properties: reservationsIds});



        if(idx<properties.length){
            let property= properties[idx];

            let property_id = property._id;
            let reservations_arrive = await reservationService.getReservations({
                date_arrival_from: moment().format('YYYY-MM-DD'),
                date_arrival_to: moment().format('YYYY-MM-DD'),
                property_id,
                status: 2
            });
            property.arrives = reservations_arrive.data.data.reservations.length;

            let cancellations = await reservationService.getReservations({
                cancelled_at_from: moment().format('YYYY-MM-DD'),
                cancelled_at_to: moment().add(1,'day').format('YYYY-MM-DD'),
                property_id,
                status:3
            });
            property.cancellations = cancellations.data.data.reservations.length;


            let pendients = await reservationService.getReservations({
                created_at_from: moment().subtract(7, 'day').format('YYYY-MM-DD'),
                created_at_to: moment().add(1,'day').format('YYYY-MM-DD'),
                //created_at_to: '2020-07-23',
                property_id,
                status:1,
                payment_method: 'ARRIVE'
            });
            //console.log('pendients', pendients);
            property.unconfirmed = pendients.data.data.reservations.length;

            idx = idx + 1;
            await this.updateData(properties, idx)

        }else{
            this.closeLoading();
            return true;
        }
      },

    // Link a mostrar resumen.
    handleShow(property_id) {
      let selected = this.properties.find(function(element){
        return property_id === element._id;
      });
      this.$store.dispatch('SELECT_PROPERTY', ...[selected]);
      this.$router.push({
        name: 'PropertyShow',
        params: {
          id: property_id
        }
      })
    },

    // Link a editar
    handleEdit(id) {
      this.$router.push({
        name: 'editProperty',
        params: {
          id: id
        }
      })
    },

    // Link a nueva propiedad
    createItem() {
      this.$router.push({
        name: 'createProperty'
      })
    },

    // Status
    // TODO: solo está español?
    trans_status(status) {
      return status ? 'Activo' : 'Desactivado';
    },

    // render de strellas de categoría.
    createStars(number) {
      let complete_stars = Math.floor(number);
      let last_stars = (complete_stars !== number) ? '<i class="fa fa-star-half-o""></i>' : '';
      let result = '';
      for (let i = 1; i <= number; i++) {
        result += '<i class="fa fa-star"></i>';
      }
      return result + last_stars;
    },
      initLoading(text = 'Cargando...') {
          const loading = Loading.service({
              lock: true,
              text: text,
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
          });
          this.fullscreenLoading = loading;
      },
      closeLoading(){
          this.fullscreenLoading.close();
      }

  },
  mounted() {
    this.fuseSearch = new Fuse(this.tableData, { keys: ['name'] })

  }
}
</script>
<style>
</style>
